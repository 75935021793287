<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language mr-2"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="0"
        badge-classes="bg-danger"
        class="text-body"
        icon="HomeIcon"
        size="21"
      />
      <span class="ml-50 text-body">{{ produto.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in produtos"
      :key="localeObj.name"
      @click="setNewProduct(localeObj)"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";

import axios from "axios";
import { baseApiUrl, userKey } from "@/global";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      produtos: [],
      produto: {},
    };
  },
  methods: {
    async getProdutos() {
      const json = localStorage.getItem(userKey);
      let userData = JSON.parse(json);
      //PEGA TODOS OS PRODUTOS E SETA ELES
      const getAllProdutos = await axios.get(`${baseApiUrl}/produtos`)
      const allProdutos = getAllProdutos.data.data
      //PEGA TODAS AS PERMISSÕES
      const getPermissionProdutos = await axios.post(`${baseApiUrl}/builder/user`, userData)
      const permissionProdutos = getPermissionProdutos.data.data.produtos
      //CONFRONTA TODOS PRODUTOS COM AS PERMISSÕES
      for (let i = 0; allProdutos.length > i; i++){
        if(permissionProdutos.some((produto) => produto === allProdutos[i]._id)) {
          this.produtos.push(allProdutos[i]) //SALVA PRODUTOS PERMITIDOS NO ARRAY PRODUTOS
        }
      }
      if(!userData.produto.name) {
        userData.produto = this.produtos[0]
      }
    this.setNewProduct(userData.produto)
      
    },
    setNewProduct(product) {
      this.produto = product
      this.$store.commit("setProduct", product)
      
      
    },
  },
  mounted() {
    this.getProdutos();
  },
};
</script>

<style>
</style>
