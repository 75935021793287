<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menu"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

import axios from "axios";
import { baseApiUrl, userKey } from "@/global";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  data() {
    return {
      menu: [],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async setMenu() {
      let menuPermission;
      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      await axios.post(`${baseApiUrl}/builder/menu`, userData).then((res) => {
        menuPermission = res.data.data;
      });
      await this.items.filter((menu) => {
        for (let i = 0; menuPermission.length > i; i++) {
          if (menuPermission[i] == menu.id) {
            this.menu.push(menu);
            return;
          }
        }
      });
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  mounted() {
    this.setMenu();
  },
};
//MENU VERTICAL LEVEL ACCESS pavão
</script>
