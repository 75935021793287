<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      
      <Product/>
      <Notification/>
      <!-- <Messages /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav" v-if="user">
            <p class="user-name font-weight-bolder mb-0" >
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.access }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
          <!-- :src="require('@/assets/images/avatars/13-small.png')" -->
        </template>
        <b-dropdown-item link-class="d-flex align-items-center">
          <router-link to="/configuracao">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Configurações</span>
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Product from "./Product.vue"
import Notification from './Notification.vue'
import Messages from './Messages.vue'

import axios from 'axios'
import { baseApiUrl ,userKey } from "@/global";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Product,
    Notification,
    Messages
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {}
    };
  },
  methods: {
    logOut() {
      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.$router.push({ path: "/login" });
    },
    async getUser() {
      const json = localStorage.getItem(userKey)
      const userData = JSON.parse(json)
      await axios.post(`${baseApiUrl}/builder/user`, userData)
        .then(res => {
          this.user = res.data.data
        })
    }
  },
  mounted() {
    this.getUser()
  }
};
</script>
