export default [
  {
    id: 0,
    title: 'DashBoard',
    route: 'DashBoard',
    icon: 'MonitorIcon',
    accessLevel: 1,
  },
  {
    id: 1,
    title: 'Usuários',
    route: 'Usuários',
    icon: 'UsersIcon',
    accessLevel: 1,
  },
  {
    id: 2,
    title: 'Agenda',
    route: 'Agenda',
    icon: 'CalendarIcon',
    accessLevel: 1,
  },
  {
    id: 3,
    title: 'Produto',
    route: 'Produto',
    icon: 'HomeIcon',
    accessLevel: 1,
  },
  {
    id: 4,
    title: 'Imobiliárias',
    route: 'Cadastros',
    icon: 'ClipboardIcon',
    accessLevel: 1,
  },
  {
    id: 5,
    title: 'Pontos',
    route: 'Pontos',
    icon: 'DiscIcon',
    accessLevel: 1,
  },
  {
    id: 6,
    title: 'Vendas',
    route: 'Vendas',
    icon: 'ZapIcon',
    accessLevel: 1,
  },
  {
    id: 7,
    title: 'Atendimento',
    route: 'Atendimento',
    icon: 'MessageSquareIcon',
    accessLevel: 1,
  },
  
]
