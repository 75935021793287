<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="systemNotifications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="gogo"
      />
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from 'axios'
import { baseApiUrl } from '@/global'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      systemNotifications: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    gogo() {
      this.$router.push({ path: '/atendimento' }).catch(err => console.log())
    },
    play() {
      var myTrack = new Audio(
        "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3"
      );
      myTrack.play();
    },
    getChamados() {
      axios.get(`${baseApiUrl}/wp/calls`).then((res) => {
        this.systemNotifications = res.data.length
      });
    },
  },
  mounted() {
    this.getChamados()
  },
  sockets: {
    newCall(message) {
      this.getChamados()
      this.play();
    },
    atualizaCall() {
      this.getChamados()
    }
  },
};
</script>

<style>
</style>
